import React from "react";

import { Helmet } from "react-helmet";

import { MouseEvent } from "react";
import { RouteComponentProps } from "@reach/router";

import clsx from "clsx";

import { navigate } from "gatsby";

import { createRef } from "react";
import { useEffect } from "react";
import { useState } from "react";

import Box from "@js/component/Box";
import Button from "@js/component/Button";
import Heading from "@js/component/Heading";
import LinkButton from "@js/component/LinkButton";
import Main from "@js/component/Main";
import Textarea from "@js/component/Textarea";

import * as classNames from "@css/component/template/Review.module.scss";

/**
 * @type ReviewProps
 */
type ReviewProps = RouteComponentProps & {
    hash?: string;
};

/**
 * @const Review
 */
const Review = (props: ReviewProps) => {
    const {
        hash
    } = props;

    const formRef = createRef<HTMLFormElement>();

    const [isFetching, setIsFetching] = useState(true);
    const [isDone, setIsDone] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [errors, setErrors] = useState<{ [key: string]: string[]; }>({});

    const [score, setScore] = useState("0");
    const [message, setMessage] = useState("");

    const [companyName, setCompanyName] = useState("...");

    const fetchPriceTiers = async () => {
        const response = await fetch(`${ process.env.GATSBY_API_PUBLIC_URL }/request/${ hash }`, {
            method: "GET"
        });

        const data = await response.json();

        if (data.status == "failed") {
            navigate("/", { replace: true });
        }
        else {
            setCompanyName(data.advisor.companyName);
        }

        setIsFetching(false);
    };

    const onSendReviewButtonClick = async (event: MouseEvent) => {
        event.preventDefault();

        if (formRef.current?.reportValidity()) {
            setIsSending(true);
            const response = await fetch(`${process.env.GATSBY_API_PUBLIC_URL}/request/review-nul-meeting/${hash}`, {
                body: JSON.stringify({
                    review: score,
                    reviewMessage: message
                }),
                method: "PUT"
            });

            const data = await response.json();

            setIsSending(false);

            if (data.status == "failed") {
                setErrors(data.errors);
            }
            else {
                setIsDone(true);
            }
        }
    };

    useEffect(() => {
        setIsDone(false);

        fetchPriceTiers();
    }, []);

    return (
        <Main>
            <Helmet>
                <title>Review plaatsen</title>
            </Helmet>
            <div className={ classNames.container }>
                <Heading className={ classNames.heading } element="h1" variant="large">
                    { !(isDone) && "Help anderen met het maken van hun keuze door een review achter te laten!" }
                    { isDone && `Bedankt voor je review over ${ companyName }` }
                </Heading>
                <Box className={ classNames.box } element="section">
                    <Heading className={ classNames.headingSub } variant="medium">
                        { !(isDone) && `Schrijf een review over ${ companyName }` }
                        { isDone && "Je review is geplaatst!" }
                    </Heading>
                    { !(isDone) &&
                        <form ref={ formRef }>
                            <ul className={ classNames.errors }>
                                { errors["#"] &&
                                  errors["#"].map((error, index) => (
                                    <li className={ classNames.error } key={ index }>{ error }</li>
                                )) }
                            </ul>
                            <fieldset className={ classNames.fieldset }>
                                <legend className={ classNames.legend }>Welk cijfer geef je jouw energielabel-adviseur?</legend>
                                { ["1", "2", "3", "4", "5","6","7","8","9","10"].map((number) => (
                                    <label className={ classNames.score } key={ number }>
                                        <input
                                            checked={ number == score }
                                            className={ classNames.input }
                                            name="review"
                                            onChange={ (event) => setScore(event.currentTarget.value) }
                                            // required
                                            type="radio"
                                            value={ number }
                                        />
                                        <div className={ classNames.number }>
                                            <span>{ number }</span>
                                        </div>
                                    </label>
                                )) }
                            </fieldset>
                            <ul className={ classNames.errors }>
                                { errors.review &&
                                  errors.review.map((error, index) => (
                                    <li className={ classNames.error } key={ index }>{ error }</li>
                                )) }
                            </ul>
                            <label className={ classNames.label } htmlFor="message">Omschrijf je ervaring</label>
                            <Textarea
                                className={ clsx(classNames.textarea, {
                                    [classNames.error]: errors.reviewMessage
                                }) }
                                id="message"
                                name="message"
                                onChange={ (event) => setMessage(event.currentTarget.value) }
                                placeholder="Begin hier te typen"
                                // required
                                value={ message }
                            />
                            <ul className={ classNames.errors }>
                                { errors.reviewMessage &&
                                  errors.reviewMessage.map((error, index) => (
                                    <li className={ classNames.error } key={ index }>{ error }</li>
                                )) }
                            </ul>
                            <div className={ classNames.buttonWrapper }>
                                <Button
                                    className={ classNames.button }
                                    disabled={ isFetching || isSending }
                                    onClick={ onSendReviewButtonClick }
                                >
                                    { !(isSending) && "Plaats review" }
                                    { isSending && "Review plaatsen..." }
                                </Button>
                            </div>
                        </form>
                    }
                    { isDone &&
                        <>
                            <div className={ classNames.done }>
                                Dank voor het plaatsen van een review en het helpen van andere gebouweigenaren met
                                het selecteren van een energielabel-adviseur!
                            </div>
                            <LinkButton className={ classNames.button } to="/">Ga terug naar home</LinkButton>
                        </>
                    }
                </Box>
            </div>
        </Main>
    );
};

export default Review;
